/** 商品路由 */
export default {
    name: 'goods',
    path: 'goods',
    meta: {
        title: '商品'
    },
    component: ()=>import('@/pages/goods/goods.vue'),
    children: [
        {
            name: 'submenu-cgoods',
            path: '/admin/goods/cgoods', //跳转的path
            meta: {
                title: 'C端商品'
            },
            children:[              
                {
                    name: 'submenu-cdiy',
                    path: '/admin/goods/cgoods/cdiy', 
                    meta: {
                        title: 'DIY页面'
                    },
                    component: ()=>import('@/pages/goods/submenu/popupmenu/cdiy.vue') 
                },
                {
                    name: 'submenu-cgroup',
                    path: '/admin/goods/cgoods/cgroup', 
                    meta: {
                        title: '特性组'
                    },
                    component: ()=>import('@/pages/goods/submenu/popupmenu/cgroup.vue') 
                },
                {
                    name: 'submenu-cclass',
                    path: '/admin/goods/cgoods/cclass', 
                    meta: {
                        title: '分类列表'
                    },
                    component: ()=>import('@/pages/goods/submenu/popupmenu/cclass.vue') 
                },
                {
                    name: 'submenu-cgoodslist',
                    path: '/admin/goods/cgoods/cgoodslist', 
                    meta: {
                        title: '商品列表'
                    },
                    component: ()=>import('@/pages/goods/submenu/popupmenu/cgoodslist.vue') 
                },
            ],
            component: ()=>import('@/pages/goods/submenu/cgoods.vue') //.vue文件
        },
    ]
}