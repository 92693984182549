import request from '@/utils/request'
// http://192.168.1.199:92/admin
//登录
export function login (data) {
    return request({
        url: '/login/login',
        method: 'post',
        data
    })
}
//退出
export function loginLogout (data) {
    return request({
        url: '/login/logout',
        method: 'post',
        data
    })
}
//修改密码
export function loginModify (data) {
    return request({
        url: '/login/modify',
        method: 'post',
        data
    })
}
//修改账号信息
export function otherModify (data) {
    return request({
        url: '/other/modify',
        method: 'post',
        data
    })
}
// 店铺
// 店铺列表
export function shopList (data) {
    return request({
        url: '/shop/list',
        method: 'get',
        params: data
    })
}
// 创建店铺
export function shopCreate (data) {
    return request({
        url: '/shop/create',
        method: 'post',
        data
    })
}
// 获取城市数据
export function get_city_list (data) {
    return request({
        url: '/other/city_select',
        method: 'post',
        data
    })
}
// 删除店铺
export function shopDel (data) {
    return request({
        url: '/shop/del',
        method: 'post',
        data
    })
}
//删除图片
export function otherDelimg (data) {
    return request({
        url: '/other/del_img',
        method: 'post',
        data
    })
}
//下拉店铺数据 
export function shopSelect (data) {
    return request({
        url: '/shop/select',
        method: 'get',
        params: data
    })
}
// 员工列表
export function staffList (data) {
    return request({
        url: '/staff/list',
        method: 'get',
        params: data
    })
}
// 通过审核或者拒绝审核
export function staffExamine (data) {
    return request({
        url: '/staff/examine',
        method: 'post',
        data
    })
}
// 修改佣金
export function staffCommission (data) {
    return request({
        url: '/staff/commission',
        method: 'post',
        data
    })
}
// 删除员工
export function staffDel (data) {
    return request({
        url: '/staff/del',
        method: 'post',
        data
    })
}
// 客户列表
export function customerList (data) {
    return request({
        url: '/customer/list',
        method: 'get',
        params: data
    })
}
// 变更上级
export function customerChangeUp (data) {
    return request({
        url: '/customer/change_up',
        method: 'post',
        data
    })
}
// 员工禁言
export function customerProh (data) {
    return request({
        url: '/customer/proh',
        method: 'post',
        data
    })
}
// 员工下拉数据
export function staffSelect (data) {
    return request({
        url: '/staff/select',
        method: 'get',
        params: data
    })
}
// 活动列表
export function activeList (data) {
    return request({
        url: '/active/list',
        method: 'get',
        params: data
    })
}
// 修改活动状态
export function modifyStatus (data) {
    return request({
        url: '/active/modify_status',
        method: 'post',
        data
    })
}
// 创建活动
export function activeCreate (data) {
    return request({
        url: '/active/create',
        method: 'post',
        data
    })
}
// 活动样式列表
export function themeList (data) {
    return request({
        url: '/theme/list',
        method: 'get',
        params: data
    })
}
// 切换活动样式
export function themeUseTheme (data) {
    return request({
        url: '/theme/use_theme',
        method: 'post',
        data
    })
}
// 创建活动样式
export function themeCreateTheme (data) {
    return request({
        url: '/theme/create_theme',
        method: 'post',
        data
    })
}
// 直播间列表
export function liveList (data) {
    return request({
        url: '/live/list',
        method: 'get',
        params: data
    })
}
// 创建直播间
export function liveCreate (data) {
    return request({
        url: '/live/create',
        method: 'post',
        data
    })
}
// 已绑定的活动
export function liveActive (data) {
    return request({
        url: '/live/live_active',
        method: 'post',
        data
    })
}
// 绑定活动
export function joinActive (data) {
    return request({
        url: '/live/join_active',
        method: 'post',
        data
    })
}
// 解绑活动
export function deleteActive (data) {
    return request({
        url: '/live/delete_active',
        method: 'post',
        data
    })
}
// 直播间图片分类列表
export function imageClass (data) {
    return request({
        url: '/live/image_class',
        method: 'get',
        params: data
    })
} 
// 直播间图片列表
export function imageList (data) {
    return request({
        url: '/live/image_list',
        method: 'get',
        params: data
    })
} 
// 创建直播间图片分类
export function createImageClass (data) {
    return request({
        url: '/live/create_image_class',
        method: 'post',
        data
    })
}
// 上传直播间图片
export function createImage (data) {
    return request({
        url: '/live/create_image',
        method: 'post',
        data
    })
}
// 切换直播间图片使用状态
export function useImage (data) {
    return request({
        url: '/live/use_image',
        method: 'post',
        data
    })
}
// 删除直播间图片
export function delImage (data) {
    return request({
        url: '/live/del_image',
        method: 'post',
        data
    })
}
// 直播间下拉
export function liveSelect (data) {
    return request({
        url: '/live/select',
        method: 'get',
        params: data
    })
} 
// 抽奖活动列表
export function drawList (data) {
    return request({
        url: '/live/draw_list',
        method: 'get',
        params: data
    })
}
// 抽奖活动中奖详情
export function drawInfoList (data) {
    return request({
        url: '/live/draw_info_list',
        method: 'get',
        params: data
    })
}
// 创建抽奖活动
export function liveAddDraw (data) {
    return request({
        url: '/live/add_draw',
        method: 'post',
        data
    })
}
// 导出抽奖详情
export function drawExport (data) {
    return request({
        url: '/live/draw_export',
        method: 'post',
        data
    })
}
// 修改抽奖状态
export function modifyDraw (data) {
    return request({
        url: '/live/modify_draw',
        method: 'post',
        data
    })
}
// 抽奖详情
export function drawInfo (data) {
    return request({
        url: '/live/draw_info',
        method: 'get',
        params: data
    })
}
// 福袋接口列表
export function bagList (data) {
    return request({
        url: '/live/bag_list',
        method: 'get',
        params: data
    })
}
// 推送抽奖
export function startBag (data) {
    return request({
        url: '/live/start_bag',
        method: 'post',
        data
    })
}
// 福袋抽奖详情
export function bagInfoList (data) {
    return request({
        url: '/live/bag_info_list',
        method: 'get',
        params: data
    })
}
// 创建福袋接口
export function addBag (data) {
    return request({
        url: '/live/add_bag',
        method: 'post',
        data
    })
}
// 福袋详情
export function bagInfo (data) {
    return request({
        url: '/live/bag_info',
        method: 'get',
        params: data
    })
}
// 订单列表
export function orderList (data) {
    return request({
        url: '/order/list',
        method: 'get',
        params: data
    })
}
// 订单数据汇总
export function toTotalExport (data) {
    return request({
        url: '/order/to_total_export',
        method: 'get',
        params: data
    })
}
// 导出订单
export function toExport (data) {
    return request({
        url: '/order/to_export',
        method: 'get',
        params: data
    })
}
// 退款
export function refund (data) {
    return request({
        url: '/order/refund',
        method: 'post',
        data
    })
}
// 退款列表
export function refundList (data) {
    return request({
        url: '/order/refund_list',
        method: 'get',
        params: data
    })
}
// 修改虚拟浏览量
export function modifyVirtually (data) {
    return request({
        url: '/live/modify_virtually',
        method: 'post',
        data
    })
}
// 跳转登录
export function loginJoin (data) {
    return request({
        url: '/login/join',
        method: 'post',
        data
    })
}
// 获取账号信息
export function otherGetInfo (data) {
    return request({
        url: '/other/get_info',
        method: 'get',
        params: data
    })
}
// 职位列表
export function shopJobList (data) {
    return request({
        url: '/shop/job_list',
        method: 'get',
        params: data
    })
}

// 爆破大屏
// 累计成交、数据总览
export function screenIndex (data) {
    return request({
        url: '/screen/index',
        method: 'get',
        params: data
    })
}
// 实时订单
export function screenOrderList (data) {
    return request({
        url: '/screen/order_list',
        method: 'get',
        params: data
    })
}
// 员工成交销量
export function screenStaffOrder (data) {
    return request({
        url: '/screen/staff_order',
        method: 'get',
        params: data
    })
}
// 商品销量
export function screenGoodsNum (data) {
    return request({
        url: '/screen/goods_num',
        method: 'get',
        params: data
    })
}
// 实时评论
export function screenComment (data) {
    return request({
        url: '/screen/comment',
        method: 'get',
        params: data
    })
}
// 直播间推流地址
export function livePush (data) {
    return request({
        url: '/live/push',
        method: 'post',
        data
    })
}
// 添加送礼
export function liveAddSendGift (data) {
    return request({
        url: '/live/add_send_gift',
        method: 'post',
        data
    })
}
// 送礼列表
export function liveGiftSendIndex (data) {
    return request({
        url: '/live/gift_send_index',
        method: 'get',
        params: data
    })
}
// 领取礼品记录
export function liveReceiveGiftList (data) {
    return request({
        url: '/live/receive_gift_list',
        method: 'get',
        params: data
    })
}
// 个人领取徽章详情
export function liveCustomerRecords (data) {
    return request({
        url: '/live/customer_records',
        method: 'get',
        params: data
    })
}
// 个人领取徽章的领取记录
export function liveCustomerXiongzhangList (data) {
    return request({
        url: '/live/customer_xiongzhang_list',
        method: 'get',
        params: data
    })
}
// 个人领取徽章详情导出
export function liveCustomerRecordsExport (data) {
    return request({
        url: '/live/customer_records_export',
        method: 'get',
        params: data
    })
}
// 待审核
export function withWithDraw (data) {
    return request({
        url: '/with/with_draw',
        method: 'get',
        params: data
    })
}
// 已审核列表
export function withWithList (data) {
    return request({
        url: '/with/with_list',
        method: 'get',
        params: data
    })
}
// 同意或拒绝审核
export function withWithDeal (data) {
    return request({
        url: '/with/with_deal',
        method: 'post',
        data
    })
}
// 直播回放下拉
export function live_back_list (data) {
    return request({
        url: '/live/back_list',
        method: 'get',
        params: data
    })
} 
// 直播回放活动
export function live_back_active (data) {
    return request({
        url: '/live/back_active',
        method: 'get',
        params: data
    })
} 
// 推荐活动
export function live_recommend_active (data) {
    return request({
        url: '/live/recommend_active',
        method: 'post',
        data
    })
}
// 讲解活动
export function live_explain_active (data) {
    return request({
        url: '/live/explain_active',
        method: 'post',
        data
    })
}
// 关闭讲解活动
export function close_explain_active (data) {
    return request({
        url: '/live/close_explain_active',
        method: 'post',
        data
    })
}
// 创建马甲
export function live_add_vest (data) {
    return request({
        url: '/live/vest',
        method: 'post',
        data
    })
}
// 当前直播在线人数
export function live_online_num (data) {
    return request({
        url: '/live/online_num',
        method: 'get',
        params: data
    })
}
// 编辑直播回放
export function edit_back_active (data) {
    return request({
        url: '/live/edit_back_active',
        method: 'post',
        data
    })
}
// 直播回放详情
export function back_active_info (data) {
    return request({
        url: '/live/back_active_info',
        method: 'get',
        params: data
    })
}