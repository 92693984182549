<template>
    <Layout :style="'min-height:' + innerHeight + 'px'">
        <Layout style="z-index: 1">
            <div
                v-show="controlEle"
                style="background-color: #323a52"
                class="main_menu"
            >
                <div class="main_menu_header">
                    <span class="main_menu_header_txt">{{ name }}</span>
                </div>
                <div class="main_menu_content">
                    <div v-for="(item, index) in muen" :key="index">
                        <MenuItem :name="item.unique_auth" :to="item.src">
                            <div
                                :class="
                                    activeId == index
                                        ? 'main_menu_content_box_active'
                                        : 'main_menu_content_box'
                                "
                                @click="eventMenu(item, index)"
                            >
                                <Icon
                                    :type="item.icon"
                                    style="font-size: 20px"
                                ></Icon>
                                <div>{{ item.title }}</div>
                            </div>
                        </MenuItem>
                    </div>
                </div>
            </div>
            <Sider
                v-show="controlEle"
                style="width: 150px; min-width: 150px; max-width: 150px"
            >
                <!--  -->
                <Menu
                    ref="menu"
                    theme="light"
                    width="150px"
                    :style="'min-height:' + innerHeight + 'px'"
                    :open-names="[openNames]"
                    accordion
                    @on-open-change="onOpenChange"
                >
                    <div>
                        <div class="logout">
                            <div
                                class="layout_nav"
                                @mouseover="showMessage"
                                @mouseleave="handleMouseOut"
                            >
                                <div class="bg_circle">
                                    欢迎您！
                                    <img
                                        v-if="!displayShowMenu"
                                        src="@/assets/icon/down.png"
                                        style="cursor: pointer; width: 20px"
                                        alt=""
                                    />
                                    <img
                                        v-if="displayShowMenu"
                                        src="@/assets/icon/up.png"
                                        style="cursor: pointer; width: 20px"
                                        alt=""
                                    />
                                </div>
                                <div
                                    style="font-size: 16px; padding-left: 10px"
                                >
                                    {{ account }}
                                </div>
                                <div class="show-menu" v-if="displayShowMenu">
                                    <div
                                        class="event_logout"
                                        @click="eventLogout"
                                    >
                                        账号设置
                                    </div>
                                    <div class="logout_btn" @click="logout">
                                        退出登录
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-for="(items, nun) in muen" :key="nun">
                            <div
                                v-for="(item, index) in items.children"
                                :key="index"
                            >
                                <Submenu
                                    :name="item.unique_auth"
                                    v-if="unique_auth == items.unique_auth"
                                >
                                    <template slot="title">
                                        <div>{{ item.title }}</div>
                                    </template>
                                    <MenuItem
                                        v-for="(child, idx) in item.children"
                                        :key="idx"
                                        :name="child.unique_auth"
                                        :to="child.src"
                                    >
                                        {{ child.title }}
                                    </MenuItem>
                                </Submenu>
                            </div>
                        </div>
                    </div>
                </Menu>
            </Sider>
            <Layout
                id="menu"
                :style="'height:' + innerHeight + 'px'"
                :class="
                    this.$route.path !=
                    '/admin/activity/activitycenter/bigScreen'
                        ? 'menu'
                        : 'menu_active'
                "
            >
                <div
                    style="background-color: #fff"
                    :style="'height:' + innerHeight + 'px'"
                    :class="
                        this.$route.path !=
                        '/admin/activity/activitycenter/bigScreen'
                            ? 'big_div'
                            : 'big_div_active'
                    "
                >
                    <div
                        v-if="controlEle"
                        class="menu_box"
                        @click="eventContract"
                    >
                        <img src="@/assets/icon/left.png" style="width: 20px" />
                    </div>
                    <div
                        v-if="!controlEle"
                        class="menu_box"
                        @click="eventContractBack"
                    >
                        <img
                            src="@/assets/icon/right.png"
                            style="width: 20px"
                        />
                    </div>
                    <Content>
                        <div
                            v-if="
                                this.$route.path !=
                                '/admin/activity/activitycenter/bigScreen'
                            "
                            class="breadcumb"
                        >
                            <div v-for="(v, i) in lists" :key="i">
                                <div>
                                    {{ v.meta.title }}
                                    <span v-if="i < lists.length - 1 && i != 0"
                                        >/&nbsp;</span
                                    >
                                </div>
                            </div>
                            <div class="amplify" @click="amplify">
                                <img
                                    src="@/assets/icon/fullscreen.png"
                                    style="cursor: pointer; width: 20px"
                                />
                            </div>
                        </div>
                        <router-view></router-view>
                    </Content>
                </div>
            </Layout>
        </Layout>
    </Layout>
</template>

<script>
import { getCookies } from "@/utils/helper";
import { loginLogout } from "@/api/index";
export default {
    data() {
        return {
            name: "",
            chainStoreLogo: "static/img/logo.jpg",
            account: "",
            innerHeight: 0,
            controlEle: true,
            activeId: -1,
            unique_auth: "",
            lists: [],
            displayShowMenu: false,
            openNames: "",
            boostValue: false, //放大缩小
        };
    },
    //监听路由变化
    watch: {
        $route(to, from) {
            let matched = to.matched; //this.$route.matched
            this.lists = matched;
            this.$nextTick(() => {
                this.$refs.menu.updateOpened();
                this.$refs.menu.updateActiveName();
            });
        },
    },
    computed: {
        muen() {
            return this.$store.state.menus;
        }
    },
    created() {
        this.innerHeight = window.innerHeight - 2;
        // 定义一个变量保存上一次记录的 innerHeight 值
        let lastInnerHeight = window.innerHeight;
        // 监听 resize 事件
        window.addEventListener("resize", () => {
            // 获取当前 innerHeight 值
            const currentInnerHeight = window.innerHeight;
            // 如果 innerHeight 值发生改变，则执行相应的操作
            if (currentInnerHeight !== lastInnerHeight) {
                // 执行你想要的方法
                this.innerHeight = window.innerHeight - 2;
                // 更新 lastInnerHeight 变量的值
                lastInnerHeight = currentInnerHeight;
            }
        });
    },
    mounted() {
        this.innerHeight = window.innerHeight - 2;
        this.account = getCookies("account");
        this.name = getCookies("name");
        // window.addEventListener('keydown', (e) => {
        //     if (e.keyCode === 27) {
        //         // 触发事件
        //         console.log('27')
        //     }
        // });
    },
    methods: {
        onOpenChange(e) {
            this.openNames = e.toString();
        },
        // 退出登录
        logout() {
            loginLogout()
                .then((res) => {
                    this.$router.push({ path: "/" });
                    localStorage.clear();
                    removeCookies("meiye-admin-api");
                    this.$Message.success(res.msg);
                })
                .catch((err) => {
                    this.$Message.error(err.msg);
                });
        },
        eventLogout() {
            this.$store.state.menus.forEach((item) => {
                this.eventMenu(item, 8);
            });
        },
        //收缩按钮
        eventContract() {
            this.controlEle = false;
            var element = document.getElementById("menu");
            element.classList.add("menu_active");
            element.classList.remove("menu");
        },
        eventContractBack() {
            this.controlEle = true;
            if (
                this.$route.path != "/admin/activity/activitycenter/bigScreen"
            ) {
                var element = document.getElementById("menu");
                element.classList.add("menu");
                element.classList.remove("menu_active");
            }
        },
        //点击主菜单
        eventMenu(e, i) {
            console.log("点击主菜单");
            this.activeId = i;
            this.unique_auth = e.unique_auth;
            if (e.children[0]) {
                this.openNames = e.children[0].unique_auth;
                setTimeout(() => {
                    this.$router.push({ path: e.children[0].children[0].src });
                }, 100);
            }
        },
        // 放大按钮
        amplify() {
            const elem = document.documentElement;
            this.boostValue = !this.boostValue;
            if (this.boostValue) {
                this.controlEle = false;
                console.log("111");
                if (elem.requestFullscreen) {
                    elem.requestFullscreen();
                } else if (elem.mozRequestFullScreen) {
                    elem.mozRequestFullScreen();
                } else if (elem.webkitRequestFullscreen) {
                    elem.webkitRequestFullscreen();
                } else if (elem.msRequestFullscreen) {
                    elem.msRequestFullscreen();
                }
            } else {
                this.controlEle = true;
                console.log("222");
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.mozCancelFullScreen) {
                    /* Firefox */
                    document.mozCancelFullScreen();
                } else if (document.webkitExitFullscreen) {
                    /* Chrome, Safari and Opera */
                    document.webkitExitFullscreen();
                } else if (document.msExitFullscreen) {
                    /* IE/Edge */
                    document.msExitFullscreen();
                }
            }
        },
        //鼠标悬浮事件
        showMessage() {
            this.displayShowMenu = true;
        },
        handleMouseOut() {
            setTimeout(() => {
                //0.5秒后执行代码
                this.displayShowMenu = false;
            }, 500);
        },
    },
};
</script>

<style scoped>
.big_div {
    padding-left: 20px;
    padding-right: 20px;
}
.big_div_active {
    padding: 0;
    overflow: hidden;
}
.breadcumb {
    display: flex;
    position: relative;
    font-size: 14px;
    margin: 20px 0 10px 0;
    height: 20px;
}
.amplify {
    position: absolute;
    right: 0;
    cursor: pointer;
}
.main_menu {
    width: 180px;
}
.main_menu_header {
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.main_menu_header_img {
    width: 44px;
    height: 44px;
    border-radius: 10px;
}
.main_menu_header_txt {
    font-size: 20px;
    color: #627897;
}
.main_menu_content {
    display: flex;
    flex-wrap: wrap;
    width: 180px;
}
.main_menu_content_box {
    width: 90px;
    height: 90px;
    color: #fff;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.main_menu_content_box:hover {
    background-color: #666;
}
.main_menu_content_box_active {
    width: 90px;
    height: 90px;
    color: #000;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
}
.logout {
    display: flex;
    align-items: center;
    cursor: pointer;
}
.layout_nav {
    margin: 0 auto;
    position: relative;
}
.bg_circle {
    width: 130px;
    height: 40px;
    font-size: 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    padding-left: 10px;
    color: #627897;
}
.show-menu {
    position: absolute;
    width: 100%;
    top: 40px;
    background-color: #eee;
    text-align: center;
    z-index: 9999;
}
.show-menu .event_logout:hover {
    border: 1px dashed #0dbc79;
    border-radius: 10px;
}
.show-menu .logout_btn:hover {
    border: 1px dashed #f00;
    border-radius: 10px;
}
.event_logout {
    color: #0dbc79;
    height: 40px;
    line-height: 40px;
    border-bottom: 1px dashed #ccc;
}
.logout_btn {
    color: #f00;
    height: 40px;
    line-height: 40px;
    border-bottom: 1px dashed #ccc;
}
.menu {
    position: relative;
    padding-left: 10px;
    background-color: #f1f6f8;
}
.menu_active {
    position: relative;
    padding: 0;
}
.menu_box {
    position: absolute;
    top: 20px;
    left: 0;
    background-color: #ebebeb;
    width: 30px;
    height: 30px;
    font-size: 20px;
    /* padding-left: 3px; */
    border-top-right-radius: 15px; /* 右上角 */
    border-bottom-right-radius: 15px; /* 右下角 */
    cursor: pointer;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>