<template>
  <div>
    <router-view/>
  </div>
</template>

<script>
export default {
    
}
</script>

<style>
@import "common/iconfont/iconfont.css";

</style>
