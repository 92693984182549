/** 概况路由 */
export default[
    {
        path:'/',
        meta: {title: '概况'},
        name:'overview',
        component: ()=>import('@/pages/overview/overview.vue'),
        children: []
    }
]
