/** 设置路由 */
export default {
    name: 'setup',
    path: 'setup',
    meta: {
        title: '设置'
    },
    component: ()=>import('@/pages/setup/setup.vue'),
    children: [
        {
            name: 'submenu-basissetup',
            path: '/admin/setup/basissetup', //跳转的path
            meta: {
                title: '基础设置'
            },
            children:[              
                {
                    name: 'submenu-numbersetup',
                    path: '/admin/setup/basissetup/numbersetup', 
                    meta: {
                        title: '账号设置'
                    },
                    component: ()=>import('@/pages/setup/submenu/popupmenu/numbersetup.vue') 
                },
                {
                    name: 'submenu-changePassword',
                    path: '/admin/setup/basissetup/changePassword', 
                    meta: {
                        title: '修改密码'
                    },
                    component: ()=>import('@/pages/setup/submenu/popupmenu/changePassword.vue') 
                },
            ],
            component: ()=>import('@/pages/setup/submenu/basissetup.vue') //.vue文件
        },
        {
            name: 'submenu-systemsetup',
            path: '/admin/setup/systemsetup', //跳转的path
            meta: {
                title: '系统设置'
            },
            children:[              
                {
                    name: 'submenu-shopsetup',
                    path: '/admin/setup/systemsetup/shopsetup', 
                    meta: {
                        title: '店铺设置'
                    },
                    component: ()=>import('@/pages/setup/submenu/popupmenu/shopsetup.vue') 
                },
                {
                    name: 'submenu-paysetup',
                    path: '/admin/setup/systemsetup/paysetup', 
                    meta: {
                        title: '支付设置'
                    },
                    component: ()=>import('@/pages/setup/submenu/popupmenu/paysetup.vue') 
                },
            ],
            component: ()=>import('@/pages/setup/submenu/systemsetup.vue') //.vue文件
        },
        {
            name: 'submenu-wananchi',
            path: '/admin/setup/wananchi', //跳转的path
            meta: {
                title: '微信公众号'
            },
            children:[              
                {
                    name: 'submenu-wananchiusher',
                    path: '/admin/setup/wananchi/wananchiusher', 
                    meta: {
                        title: '公众号引导'
                    },
                    component: ()=>import('@/pages/setup/submenu/popupmenu/wananchiusher.vue') 
                },
            ],
            component: ()=>import('@/pages/setup/submenu/wananchi.vue') //.vue文件
        },
        {
            name: 'submenu-applet',
            path: '/admin/setup/applet', //跳转的path
            meta: {
                title: '微信小程序'
            },
            children:[              
                {
                    name: 'submenu-appletusher',
                    path: '/admin/setup/applet/appletusher', 
                    meta: {
                        title: '小程序引导'
                    },
                    component: ()=>import('@/pages/setup/submenu/popupmenu/appletusher.vue') 
                },
            ],
            component: ()=>import('@/pages/setup/submenu/applet.vue') //.vue文件
        },
        {
            name: 'submenu-video',
            path: '/admin/setup/video', //跳转的path
            meta: {
                title: '微信视频号'
            },
            children:[              
                {
                    name: 'submenu-videousher',
                    path: '/admin/setup/video/videousher', 
                    meta: {
                        title: '视频号引导'
                    },
                    component: ()=>import('@/pages/setup/submenu/popupmenu/videousher.vue') 
                },
            ],
            component: ()=>import('@/pages/setup/submenu/video.vue') //.vue文件
        },
    ]
}