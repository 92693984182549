/** 活动路由 */
export default {
    name: 'activity',
    path: 'activity',
    meta: {
        title: '活动'
    },
    component: ()=>import('@/pages/activity/activity.vue'),
    children: [
        {
            name: 'submenu-activitycenter',
            path: '/admin/activity/activitycenter',
            meta: {
                title: '活动中心'
            },
            children:[              
                {
                    name: 'submenu-activitylist',
                    path: '/admin/activity/activitycenter/activitylist', 
                    meta: {
                        title: '活动列表'
                    },
                    component: ()=>import('@/pages/activity/submenu/popupmenu/activitylist.vue') 
                },
                {
                    name: 'submenu-activitystyle',
                    path: '/admin/activity/activitycenter/activitystyle', 
                    meta: {
                        title: '活动样式'
                    },
                    component: ()=>import('@/pages/activity/submenu/popupmenu/activitystyle.vue') 
                },
                {
                    name: 'submenu-bigScreen',
                    path: '/admin/activity/activitycenter/bigScreen', 
                    meta: {
                        title: '爆破大屏'
                    },
                    component: ()=>import('@/pages/activity/submenu/popupmenu/bigScreen.vue') 
                },
            ],
            component: ()=>import('@/pages/activity/submenu/activitycenter.vue'),
        },
    ]
}