/**直播路由 */
export default{
        name:'live',
        path:'live',
        meta: {title: '直播'},
        component: ()=>import('@/pages/live/live.vue'),
        children: [
            {
                name: 'submenu-liveFront',
                path: '/admin/live/liveFront', //跳转的path
                meta: {
                    title: '直播前'
                },
                children:[              
                    {
                        name: 'submenu-livefrontlist',
                        path: '/admin/live/liveFront/livefrontlist', 
                        meta: {
                            title: '直播列表'
                        },
                        component: ()=>import('@/pages/live/submenu/popupmenu/livefrontlist.vue') 
                    },
                ],
                component: ()=>import('@/pages/live/submenu/liveFront.vue') //.vue文件
            },
            {
                name: 'submenu-liveIn',
                path: '/admin/live/liveIn',
                meta: {
                    title: '直播中'
                },
                children:[              
                    {
                        name: 'submenu-liveinimg',
                        path: '/admin/live/liveIn/liveinimg', 
                        meta: {
                            title: '直播图片'
                        },
                        component: ()=>import('@/pages/live/submenu/popupmenu/liveinimg.vue') 
                    },
                    {
                        name: 'submenu-liveindraw',
                        path: '/admin/live/liveIn/liveindraw', 
                        meta: {
                            title: '直播抽奖'
                        },
                        component: ()=>import('@/pages/live/submenu/popupmenu/liveindraw.vue') 
                    },
                    {
                        name: 'submenu-liveindrawAdd',
                        path: '/live/liveIn/liveindraw/setup',
                        meta: {
                            title: '直播抽奖'
                        },
                        component: ()=>import('@/pages/live/submenu/popupmenu/liveindrawSetUp.vue') 
                    },
                    {   
                        name: 'submenu-liveindrawEdit',
                        path: '/live/liveIn/liveindraw/editSetup',
                        meta: {
                            title: '直播抽奖'
                        },
                        component: ()=>import('@/pages/live/submenu/popupmenu/liveindrawSetUp.vue') 
                    },
                    {
                        name: 'submenu-liveinbag',
                        path: '/admin/live/liveIn/liveinbag',
                        meta: {
                            title: '直播福袋'
                        },
                        component: ()=>import('@/pages/live/submenu/popupmenu/liveinbag.vue') 
                    },
                    {
                        name: 'submenu-liveinbagAdd',
                        path: '/live/liveIn/liveinbag/setUp', 
                        meta: {
                            title: '直播福袋'
                        },
                        component: ()=>import('@/pages/live/submenu/popupmenu/liveinbagSetUp.vue') 
                    },
                    {
                        name: 'submenu-liveinbagEdit',
                        path: '/live/liveIn/liveinbag/editSetup', 
                        meta: {
                            title: '直播福袋'
                        },
                        component: ()=>import('@/pages/live/submenu/popupmenu/liveinbagSetUp.vue')
                    },
                    {
                        name: 'submenu-liveinqa',
                        path: '/admin/live/liveIn/liveinqa', 
                        meta: {
                            title: '直播问答'
                        },
                        component: ()=>import('@/pages/live/submenu/popupmenu/liveinqa.vue') 
                    },
                    {
                        name: 'submenu-liveingift',
                        path: '/admin/live/liveIn/liveingift', 
                        meta: {
                            title: '直播送礼'
                        },
                        component: ()=>import('@/pages/live/submenu/popupmenu/liveingift.vue') 
                    },
                    {
                        name: 'submenu-liveingiftDetails',
                        path: '/live/liveIn/liveingift/details', 
                        meta: {
                            title: '直播送礼'
                        },
                        component: ()=>import('@/pages/live/submenu/popupmenu/liveingiftDetails.vue') 
                    },
                    {
                        name: 'submenu-personalDetails',
                        path: '/live/liveIn/liveingift/personalDetails', 
                        meta: {
                            title: '直播送礼'
                        },
                        component: ()=>import('@/pages/live/submenu/popupmenu/personalDetails.vue') 
                    },
                    {
                        name: 'submenu-liveinplugflow',
                        path: '/admin/live/liveIn/liveinplugflow', 
                        meta: {
                            title: '直播推流'
                        },
                        component: ()=>import('@/pages/live/submenu/popupmenu/liveinplugflow.vue') 
                    },
                ],
                component: ()=>import('@/pages/live/submenu/liveIn.vue'),
            },
            
            {
                name: 'submenu-liveAfter',
                path: '/admin/live/liveAfter',
                meta: {
                    title: '直播后'
                },
                children:[              
                    {
                        name: 'submenu-liveafterplayback',
                        path: '/admin/live/liveAfter/liveafterplayback', 
                        meta: {
                            title: '直播回放'
                        },
                        component: ()=>import('@/pages/live/submenu/popupmenu/liveafterplayback.vue') 
                    },
                ],
                component: ()=>import('@/pages/live/submenu/liveAfter.vue')
            },
        ]
}
