import Vue from 'vue'
import VueRouter from 'vue-router'
import {MainLayout} from "@/components/mainLayout"
import overview from "./modules/overview"
import shop from './modules/shop'
import goods from "./modules/goods"
import activity from "./modules/activity"
import setup from "./modules/setup"
import live from "./modules/live"
import order from "./modules/order"
import custom from "./modules/custom"
import finance from "./modules/finance"

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'hash',
    routes: [
        {
        name: '登录',
        path: '/',
            component: () => import("@/pages/login/login.vue")
        },
        {
            path: '/admin',
            // meta: { title: '管理后台' },
            component: MainLayout,
            children: [
                ...overview,
                shop,
                goods,
                activity,
                setup,
                live,
                order,
                custom,
                finance
            ]
        },
    ]
})

// const router = new VueRouter({
//   mode: 'history',
//   base: process.env.BASE_URL,
//   routes
// })

router.beforeEach((to, from, next) => {
  window.document.title = "管理后台";
  next();
})
export default router
