<template>
    <div style="display: flex;align-items: center;">
        <Cascader :data="data" style="width:300px" @on-change="changeCascaderValue" v-model="default_citys"></Cascader>
        <Icon style="margin-left:5px;cursor: pointer" size="18" @click="loadList" type="md-refresh" />
    </div>
</template>
    
    <script>
        import {get_city_list} from "@/api/index"
        export default {
            name: "CitySelect",
            props:{
                default_citys:{
                    type:Array,
                    default:[]
                }
            },
            data () {
                return {
                    code:"",
                    data: [],
                    submitForm:{}
                }
            },
            mounted() {
                this.code = "";
                this.loadList()
            },
            methods: {
                loadList(){
                    let city_json_data = localStorage.getItem('city_json_data')
                    if (city_json_data) {
                        this.data = JSON.parse(city_json_data)
                        return
                    }
                    get_city_list({code:this.code}).then(res=>{
                        if(res.code == 200) {
                            this.data = res.data
                            localStorage.setItem('city_json_data', JSON.stringify(res.data))
                        } else {
                            this.$Message.error(res.msg);
                        }
                    }).catch(err=>{
                        console.log(err)
                    })
                },
                changeCascaderValue(v,e){
                    this.submitForm.city_number = v
                    let names = []
                    for (var i in e) {
                        names.push(e[i].label)
                    }
                    this.submitForm.city_name = names.join("")
                    this.$emit("select_city", this.submitForm)
                }
            }
        }
    </script>
    
    <style scoped>
    
    </style>
    