/**客户路由 */
export default{
        name:'custom',
        path:'custom',
        meta: {title: '客户'},
        name:'custom',
        component: ()=>import('@/pages/custom/custom.vue'),
        children: [
            {
                name: 'submenu-customrun',
                path: '/admin/custom/customrun',
                meta: {
                    title: '客户管理'
                },
                children:[              
                    {
                        name: 'submenu-customlist',
                        path: '/admin/custom/customrun/customlist', 
                        meta: {
                            title: '用户列表'
                        },
                        component: ()=>import('@/pages/custom/submenu/popupmenu/customlist.vue') 
                    },
                    {
                        name: 'submenu-customWithdrawal',
                        path: '/admin/custom/customrun/customWithdrawal', 
                        meta: {
                            title: '提现审核'
                        },
                        component: ()=>import('@/pages/custom/submenu/popupmenu/customWithdrawal.vue') 
                    },
                ],
                component: ()=>import('@/pages/custom/submenu/customrun.vue'),
            },
        ]
}
