/** 店铺路由 */
export default {
    name: 'shop',
    path: 'shop',
    meta: {
        title: '店铺'
    },
    component: ()=>import('@/pages/shop/shop.vue'),
    children: [
        {
            name: 'submenu-shoprun',
            path: '/admin/shop/shoprun',
            meta: {
                title: '店铺管理'
            },
            children:[              
                {
                    name: 'submenu-shoplist',
                    path: '/admin/shop/shoprun/shoplist', 
                    meta: {
                        title: '店铺列表'
                    },
                    component: ()=>import('@/pages/shop/submenu/popupmenu/shoplist.vue') 
                },
                {
                    name: 'submenu-process',
                    path: '/admin/shop/shoprun/process', 
                    meta: {
                        title: '员工审核'
                    },
                    component: ()=>import('@/pages/shop/submenu/popupmenu/process.vue') 
                },
            ],
            component: ()=>import('@/pages/shop/submenu/shoprun.vue'),
        },
    ]
}