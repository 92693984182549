/**订单路由 */
export default{
        name:'order',
        path:'order',
        meta: {title: '订单'},
        name:'order',
        component: ()=>import('@/pages/order/order.vue'),
        children: [
            {
                name: 'submenu-query',
                path: '/admin/order/query',
                meta: {
                    title: '交易查询'
                },
                children:[              
                    {
                        name: 'submenu-orderlist',
                        path: '/admin/order/query/orderlist', 
                        meta: {
                            title: '订单列表'
                        },
                        component: ()=>import('@/pages/order/submenu/popupmenu/orderlist.vue') 
                    },
                ],
                component: ()=>import('@/pages/order/submenu/query.vue'),
            },
        ]
}
